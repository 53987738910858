import React from "react";
import "./Styles/App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./Containers/Home/Home";

import Footer from "./Components/Footer";
import Contact from "./Containers/Contact_Us/Contact";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Netherlands from "./Containers/Netherlands/Netherlands";
import About from "./Containers/About_Us/About_Us";
import Register from "./Containers/Register/register";
import MatchCentere from "./Containers/Match_centere/Match_centere";
import Privacy from "./Containers/privacy_policy/privacy";
import Member from "./Containers/Member/member";
import Event from "./Containers/Event/Event";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/cricket_netherland" exact>
            <Netherlands />
          </Route>
          <Route path="/About_Us" exact>
            <About />
          </Route>
          <Route path="/register" exact>
            <Register />
          </Route>
          <Route path="/Contact_Us" exact>
            <Contact />
          </Route>
          <Route path="/MatchCentere" exact>
            <MatchCentere />
          </Route>
          <Route path="/Privacy" exact>
            <Privacy />
          </Route>
          <Route path="/Event" exact>
            <Event />
          </Route>

          <Route path="/Members" exact>
            <Member />
          </Route>

          <Redirect to="/" />
        </Switch>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
