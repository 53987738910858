import "../Member/member.css";
import React from "react";
import Navbar from "../../Components/Navbar";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
// import Our_Service from "../../Assets/Service/Our_service_Img.png";
import Card from "../../Components/Card";
// import Card1 from "../../Assets/Home/YadavAnkit.png";
// import { Link } from "react-router-dom";
// import Card2 from "../../Assets/Home/Hasan.png";
// import Card3 from "../../Assets/Home/Gohil.png";
// import Card4 from "../../Assets/Home/Jipat.png";
import stick from "../../Assets/Home/logo (4).png";
// import Card5 from "../../Assets/Home/Wafa.png";
// import Card6 from "../../Assets/Home/Jamindar.png";
// import Card7 from "../../Assets/Home/Jagtap.png";
// import Card8 from "../../Assets/Home/patil.png";
// import Card9 from "../../Assets/Home/Wafa.png";
// import Card10 from "../../Assets/Home/Wafa.png";
// import Card11 from "../../Assets/Home/bawane.png";
// import Card12 from "../../Assets/Home/vaspari.png";
// import Card13 from "../../Assets/Home/Wafa.png";
// import Card14 from "../../Assets/Home/perala.png";
// import Card15 from "../../Assets/Home/alluri.png";
// import Card16 from "../../Assets/Home/Wafa.png";
// import Card17 from "../../Assets/Home/solanki.png";
// import Card18 from "../../Assets/Home/Wafa.png";
// import Card19 from "../../Assets/Home/kurali.png";
// import Card20 from "../../Assets/Home/gedada.png";
// import Card21 from "../../Assets/Home/shri.png";
// import Card22 from "../../Assets/Home/Wafa.png";
// import Card23 from "../../Assets/Home/Wafa.png";
// import Card24 from "../../Assets/Home/sharma.png";
// import Card25 from "../../Assets/Home/Mir.png";
// import Card26 from "../../Assets/Home/Wafa.png";
// import Card27 from "../../Assets/Home/Wafa.png";
// import Card28 from "../../Assets/Home/Wafa.png";
// import Card29 from "../../Assets/Home/kumar.png";
// import Card30 from "../../Assets/Home/kalikir.png";
// import Card31 from "../../Assets/Home/Jamindar (16).png";
// import Card32 from "../../Assets/Home/Wafa.png";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, Container, Typography } from "@material-ui/core";
function Member() {
  const [value, setValue] = React.useState(0);
  const [matchData, setMatchData] = React.useState();
  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Members"));
      const dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ id: doc.id, ...doc.data() });
      });
      // Sort the data by date
      dataArray.sort((a, b) => {
        const nameA = a.Name.toUpperCase();
        const nameB = b.Name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      console.log(dataArray); // Process or display the sorted data
      // Store the sorted data in a useState hook
      setMatchData(dataArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const theme = useTheme();

  // const result = [
  //   {
  //     name: "Yadav, A(Ankit) ",
  //     location: Card1,
  //   },
  //   {
  //     name: "Hasan,A(Assad)",
  //     location: Card2,
  //   },
  //   {
  //     name: "Gohil,D(Deven)",
  //     location: Card3,
  //   },
  //   {
  //     name: "jipat,D(Diesh Kumar)",
  //     location: Card4,
  //   },
  //   {
  //     name: "Wafa,F.(Farid)",
  //     location: Card5,
  //   },
  //   {
  //     name: "Jamindar,G(Gunjan)",
  //     location: Card6,
  //   },
  //   {
  //     name: "Jagtap,M(Mahesh)",
  //     location: Card7,
  //   },
  //   {
  //     name: "Patil,N(Nandan)",
  //     location: Card8,
  //   },
  //   {
  //     name: "Mapara,N(Nimish) ",
  //     location: Card9,
  //   },
  //   {
  //     name: "Kazmi,K(Kasim)",
  //     location: Card10,
  //   },
  //   {
  //     name: "Bawane,P(Parag)",
  //     location: Card11,
  //   },
  //   {
  //     name: "Vaspari,P(Parushuramudu)",
  //     location: Card12,
  //   },
  //   {
  //     name: "Pendru,P(Praneet)",
  //     location: Card13,
  //   },
  //   {
  //     name: "Perala,P(Pratheek)",
  //     location: Card14,
  //   },
  //   {
  //     name: "Alluri, P (Praveenkumar)",
  //     location: Card15,
  //   },
  //   {
  //     name: "Seetamraju, R (Ratan)",
  //     location: Card16,
  //   },
  //   {
  //     name: "Solanki, R (Rohan) ",
  //     location: Card17,
  //   },
  //   {
  //     name: "Mehta, S (Shreyash)",
  //     location: Card18,
  //   },
  //   {
  //     name: "Kurali, J (Jayendra Sreekar)",
  //     location: Card19,
  //   },
  //   {
  //     name: "Geddada, S (Sridhar)",
  //     location: Card20,
  //   },
  //   {
  //     name: "Shrivastava, S (Subhanshu) ",
  //     location: Card21,
  //   },
  //   {
  //     name: "Mentem, SR (Sundeep Raj)",
  //     location: Card22,
  //   },
  //   {
  //     name: "Wavhal, S (Swapnil)",
  //     location: Card23,
  //   },
  //   {
  //     name: "Sharma, U. (Uday)",
  //     location: Card24,
  //   },
  //   {
  //     name: "Mir, U (Umer) ",
  //     location: Card25,
  //   },
  //   {
  //     name: "Chisty, U (Umair)",
  //     location: Card26,
  //   },
  //   {
  //     name: "Unni, U (Unni)",
  //     location: Card27,
  //   },
  //   {
  //     name: "Jupalli, V (Vijay)",
  //     location: Card28,
  //   },
  //   {
  //     name: "Kumar, V. (Vishal) ",
  //     location: Card29,
  //   },
  //   {
  //     name: "Kalikiri, V (Vishnu Chaithanya)",
  //     location: Card30,
  //   },
  //   {
  //     name: "Chibber,V (victor)",
  //     location: Card31,
  //   },
  //   {
  //     name: "Sharma, Y (Yishu)",
  //     location: Card32,
  //   },
  // ];

  return (
    <div>
      <Navbar members={true} />
      <div className="design">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <img className="mg" src={stick} alt="sticker" />
          <div className="Vl"></div>
          <div className="text">
            <div>
              <h1 style={{ margin: "3rem 0", color: "white" }}>
                {" "}
                CC De Kikkers
              </h1>
            </div>
            <div className="text2">
              <p>Since 1973</p>
            </div>
          </div>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div className="block">
              <div className="b1">
                <p>CAPTAIN - SHREYAS MEHTA</p>
              </div>
              <div className="b2">
                <p>VICE CAPTAIN - ROHAN SOLANKI</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <div className="block">
              <div className="b1">
                <p>CAPTAIN - DINESH JIPAT</p>
              </div>
              <div className="b2">
                <p>VICE CAPTAIN - VISHNU CHAITHANYA</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <div className="block">
              <div className="b1">
                <p>CAPTAIN - VISHNU CHAITHANYA</p>
              </div>
              <div className="b2">
                <p>VICE CAPTAIN - ROHAN SOLANKI</p>
              </div>
            </div>
          </TabPanel>
        </div>
        <Container maxWidth="sm">
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Kikkers 1" {...a11yProps(0)} />

              <Tab label="Kikkers 2" {...a11yProps(1)} />

              <Tab label="T20" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
        </Container>
      </div>
      <div className="Blog_Content">
        <div className="Latest_Article">
          <h1 style={{ margin: "4rem", textAlign: "center" }}>Members</h1>
        </div>
        <div className="cards">
          {matchData?.map((e) => {
            return (
              <div className="Blog_Cards">
                <div className="Cards_Home">
                  <Card Card_Image={e.Img} Card_Heading={e.Name} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Member;
