import React from "react";
import "../Home/Home.css";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Navbar from "../../Components/Navbar";
import Slide from "react-reveal/Slide";
import image2 from "../../Assets/Home/image 2.png";
import Facilities from "../FacilityCarousel";
import person1 from "../../Assets/About_us/Ellipse 5.png";
import person2 from "../../Assets/About_us/Ellipse 6.png";
import person3 from "../../Assets/About_us/Ellipse 7.png";
import person4 from "../../Assets/About_us/Ellipse 8.png";
import person5 from "../../Assets/About_us/Ellipse 9.png";
function About_Us() {
  return (
    <div className="Home">
      <Navbar />

      <div
        className="Our_Service_Group"
        style={{ background: "#EAF3FE", paddingBottom: "5rem", marginTop: 0 }}
      >
        <div className="Our_Service_Txt">
          <h1>About Us</h1>
          <hr className="Underline_HR"></hr>
          <p2>
            Welcome to the official website of CC De Kikkers Club. One of the
            oldest and most prestigious clubs in the Netherlands , founded in
            1974. located in Weteringbrug , a beautiful village in the Dutch
            province of North Holland, the club has a long and rich history of
            promoting and playing the game of cricket.
          </p2>
          <Link to="/service">
            <button className="Know_More_btn">
              Know More <ArrowRightAltIcon className="arrow" />
            </button>
          </Link>
        </div>
        <div>
          <img className="Our_Service_Img" src={image2} alt="Our_Service_Img" />
          <h1 style={{ color: "#1877F2" }}>CC De Kikkers Club</h1>
        </div>
      </div>

      <div
        className="Our_Service_Group"
        style={{ marginTop: 0, background: "#fff" }}
      >
        <Slide left cascade>
          <div style={{ paddingBottom: "7rem" }} className="Our_Service_Txt">
            <h1> Our History </h1>
            <hr className="Underline_HR"></hr>
            <p2>
              The Club was established in 1974. It was founded with the mission
              of proving a platform for cricket players of all ages and
              background to come together and play the game they loved, while
              promoting teamwork, sportsmanship, and fair play. In it’s early
              years, the club faced a number of challenges, including a lack of
              resources and faciliyies. However, with the dedication and hard
              work of it’s members, the Club began to establish itself as
              respected and successful club in the Dutch cricket scene.
            </p2>
          </div>
        </Slide>
      </div>
      {/* <hr className="HR"></hr> */}

      <div
        className="Our_Service_Group"
        style={{ marginTop: 0, background: "#EAF3FE" }}
      >
        <div style={{ paddingBottom: "8rem" }} className="Our_Service_Txt">
          <h1> Our Mission </h1>

          <p2>
            Today, the mission of the CC De Kikkers Club remains the same: to
            provide a welcoming and inclusive environment for players of all
            levels to enjoy the game of cricket. The club is committed to
            promoting the values of sportsmanship, teamwork, and fair play, and
            to fostering a sense of community among it’s members. The Club also
            places a strong emphasis on youth development, and plans to offers a
            range of programs and initiatives designed to help young players
            develop their skills and passion for the game. Overall, the CC De
            Kikkers is a club with a rich history and a bright future, and
            remains committed to it’s mission of promoting cricket and fostering
            a sense of community among it’s members.
          </p2>
        </div>
      </div>
      <div
        className="Our_Service_Group"
        style={{ marginTop: 0, background: "#fff" }}
      >
        <Slide left cascade>
          <div
            style={{ paddingBottom: "7rem" }}
            className="Our_Service_Txt"
            id="facility"
          >
            <h1> Our Facilities</h1>
            <hr className="Underline_HR"></hr>
            <p2>
              CC De Kikkers is proud to offer a range of facilities for its
              player and members, including cricket kits, changing rooms, a club
              house, and recreational areas such as pool table, table tennis and
              a gym. These facilities are designed to provide a comfortable and
              enjoyable experience for all, whether they are practicing, playing
              matches, or socializing. CC De Kikkers is committed to providing
              quality facilities that cater to the need of its members, and
              strives to offer a welcoming and inclusive environment for
              everyone who loves the game of cricket.
            </p2>
          </div>
        </Slide>
      </div>
      <div style={{ margin: "4rem" }}>
        <Facilities />
      </div>
      <div
        style={{
          marginTop: 0,
          background: "#EAF3FE",
          paddingLeft: "5rem",
          paddingTop: "2rem",
        }}
      >
        <h1>Committee Members</h1>
      </div>

      <div
        className="Our_Service_Group"
        style={{
          marginTop: 0,
          background: "#EAF3FE",
          justifyContent: "space-around",
        }}
      >
        <div style={{ paddingBottom: "2rem" }} className="Our_Service_Txt">
          <div
            style={{
              margin: "2rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "3rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "18rem",
              }}
            >
              <img src={person2} style={{ width: "70%" }} alt="Our_Story_Img" />
              <h2 className="Card_Heading">Umair Chisty</h2>
              <p2 className="Card_Para">Chairman</p2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "18rem",
              }}
            >
              <img src={person3} style={{ width: "70%" }} alt="Our_Story_Img" />
              <h2 className="Card_Heading">Victor Chibber</h2>
              <p2 className="Card_Para">Treasurer</p2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "18rem",
              }}
            >
              <img src={person1} style={{ width: "70%" }} alt="Our_Story_Img" />
              <h2 className="Card_Heading">Nandan Patil</h2>
              <p2 className="Card_Para">Secretary</p2>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "18rem",
              }}
            >
              <img src={person4} style={{ width: "70%" }} alt="Our_Story_Img" />
              <h2 className="Card_Heading">Pratheek Perala</h2>
              <p2 className="Card_Para">Competition Secretary</p2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "18rem",
              }}
            >
              <img src={person5} style={{ width: "70%" }} alt="Our_Story_Img" />
              <h2 className="Card_Heading">Parag Bawane</h2>
              <p2 className="Card_Para">Team Manager</p2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About_Us;
