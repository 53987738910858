import React from "react";
import Navbar from "../../Components/Navbar";
import "../Home/Home.css";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import YoutubeEmbed from "../../Components/YoutubeEmbed";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

// const CustomButton = ({ onClick, children }) => (

//   <button
//     style={{
//       backgroundColor: "gray",
//       color: "white",
//       border: "none",
//       borderRadius: "5px",
//       padding: "10px",
//       margin: "5px",
//       cursor: "pointer",
//       position: "absolute",
//       left: "2px",
//       top: "1px",
//     }}
//     onClick={onClick}
//   >
//     {children}
//   </button>
// );
// const CustomButton1 = ({ onClick, children }) => (
//   <button
//     style={{
//       backgroundColor: "gray",
//       color: "white",
//       border: "none",
//       borderRadius: "5px",
//       padding: "10px",
//       margin: "5px",
//       cursor: "pointer",
//       position: "absolute",
//       right: "1rem",
//       zIndex: "2",
//     }}
//     onClick={onClick}
//   >
//     {children}
//   </button>
// );

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));
function MatchCentere(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="">
      <Navbar Matchcentere="true" />
      {/* <div className="Banner" style={{ height: "50rem" }}></div> */}
      {/* <div>
        <Carousel
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && (
              <CustomButton1 onClick={onClickHandler}>
                Next Matches
              </CustomButton1>
            )
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && (
              <CustomButton onClick={onClickHandler}>
                Previous matches
              </CustomButton>
            )
          }
          ref={carouselRef}
        >
          <div
            className="video"
            style={{ width: "80%", margin: "auto", marginTop: "5rem" }}
          >
            <YoutubeEmbed embedId="yjZLvYgzqhI" />
          </div>
          <div>ETst</div>
          <div>TEVEV</div>
        </Carousel>
      </div> */}
      <div>
        <Container maxWidth="sm">
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="De Kikkers 1" {...a11yProps(0)} />

              <Tab label="De Kikkers 2" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
        </Container>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            {/* <div style={{ width: "40%", margin: "auto", minWidth: "25rem" }}> */}
            <Container maxWidth="sm">
              <YoutubeEmbed embedId="playlist?list=PLITkk12r7lRO-oD3tIQh2g9ZyIvDxX-qm" />
            </Container>
            <Container
              maxWidth="md"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: "2rem",
                gap: "2rem",
              }}
            >
              <iframe
                src="https://matchcentre.kncb.nl/matches/?entity=134453&grade=73941&season=15&team=136547"
                style={{
                  height: "70rem",
                  width: "40%",
                  minWidth: "25rem",
                }}
                allowfullscreen=""
                className="iframefixtures"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <iframe
                src="https://matchcentre.kncb.nl/ladders/?entity=134453&grade=73941&season=15"
                style={{
                  height: "70rem",
                  width: "40%",
                  minWidth: "25rem",
                  padding: "1.0rem",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Container>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Container maxWidth="sm" style={{ marginBottom: "2rem" }}>
              <YoutubeEmbed embedId="playlist?list=PLITkk12r7lRM66Gpj0Jb7e5kHO9CRkrvj" />
            </Container>
            <Container
              maxWidth="md"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: "2rem",
                gap: "2rem",
              }}
              className="Fixtures"
            >
              <iframe
                src="https://matchcentre.kncb.nl/matches/?entity=134453&grade=73942&season=15&team=136547"
                style={{
                  height: "70rem",
                  width: "40%",
                  minWidth: "25rem",
                  padding: "1.0rem",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <iframe
                src="https://matchcentre.kncb.nl/ladders/?entity=134453&grade=73942&season=15"
                style={{
                  height: "70rem",
                  width: "40%",
                  minWidth: "25rem",
                  padding: "1.0rem",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Container>
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
}

export default MatchCentere;
