import { useState } from "react";
import "../Register/register.css";
import "../Home/Home.css";
import Navbar from "../../Components/Navbar";
import regiImg from "../../Assets/About_us/Rectangle 12.png";
import Zoom from "react-reveal/Zoom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storage } from "../../firebase";
import { ref, getDownloadURL } from "firebase/storage";

import { uploadBytesResumable } from "firebase/storage";
import {
  Paper,
  Box,
  Grid,
  Typography,

  // Checkbox,
  Button,
} from "@material-ui/core";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import blue from "@material-ui/core/colors/blue";

import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "1.5rem",
  },
  icon: {
    margin: theme.spacing(2),
  },
  cardContainer: {
    width: "100px",
    margin: "10px",
  },
  cardRoot: {
    paddingBottom: "14px !important",
  },
  cardRootHide: {
    paddingBottom: "14px !important",
    margin: "-16px",
  },
  input: {
    display: "none",
  },
  button: {
    color: blue[900],
    margin: 10,
  },
  logo: {
    width: "100px",
    height: "100px",
  },
  submit: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "120px",
  },
}));

function Register() {
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    LastName: Yup.string().required("Last name is required"),
    Address: Yup.string()
      .required("Address is required")
      .min(7, "Address must be at least 7 characters"),
    City: Yup.string().required("Address is required"),
    Postal: Yup.string().required("Address is required"),
    PhoneNumber: Yup.number()
      .required("Address is required")
      .min(10, "PhoneNumber must be at least 10 characters"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    Date: Yup.string().required("Date Of Birth is required"),
    // acceptTerms: Yup.bool().oneOf([true], "Accept Terms is required"),
  });

  const {
    register,

    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const notify = () => toast("Registration Form Successfully Submitted!");
  const notifySign = () => toast("Please upload signature to register");
  const [file, setFile] = useState();
  //   function handleChange(e) {
  //     console.log(e.target.files);
  //     setFile(e.target.files[0]);
  //   }
  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setFile(file);
      reader.onloadend = function (e) {
        setImage(reader.result);
        setUploadState("uploaded");
      };
    }
  };
  const handleResetClick = (event) => {
    setImage(null);
    setUploadState("initial");
    reset({ logo: null });
  };

  const [progressPercent, setProgressPercent] = useState(0);
  const classes = useStyles();
  const [uploadState, setUploadState] = useState("initial");
  const [image, setImage] = useState("");

  const onSubmit = (data, e) => {
    console.log(JSON.stringify(data, null, 2));
    console.log(file);

    if (!file) {
      return null;
    }

    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressPercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(storageRef).then((downloadURL) => {
          console.log(downloadURL);
          if (downloadURL !== "") {
            var sign = { signature: downloadURL };
            data = { ...data, ...sign };
          }
          if (data !== "" && downloadURL !== "") {
            console.log(data);
            addDoc(collection(db, "registration"), {
              data,
            })
              .then((re) => {
                console.log(re);
                notify();
                reset();
                setProgressPercent(0);
                setImage(null);
                setUploadState("initial");
                reset({ logo: null });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    );
  };

  return (
    <div>
      <Navbar />
      <ToastContainer />
      <div className="regBack">
        <div
          className="Our_Service_Group"
          style={{
            padding: 0,
            margin: "0",
            background:
              "linear-gradient(180deg, #0E105E 0%, rgba(0, 0, 0, 0.81) 100%)",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2);",
          }}
        >
          <div
            style={{ justifyContent: "center", padding: "5rem" }}
            className="Our_Service_Txt"
          >
            <h1 style={{ margin: "3rem 0", color: "white" }}>
              {" "}
              Cricket Club De Kikkers
            </h1>
            <p2 style={{ color: "white" }}>
              Cricket is a popular bat-and-ball game that is widely played and
              followed in many parts of the world. It originated in England and
              is now played in more than 100 countries, making it one of the
              most popular sports globally. The game involves two teams of
              eleven players each, with one team batting and the other team
              fielding.
            </p2>
          </div>
          <img className="Bat" src={regiImg} alt="Our_Story_Img" />
        </div>
      </div>
      <div className="Banner_Label">
        <Zoom cascade>
          <div>
            <h2 className="Banner_Label_Content">
              "Don't miss out on the opportunity to join a club which will offer
              your amazing opportunities! Fill out our quick and easy
              registration form today to get started."
            </h2>
          </div>
        </Zoom>
      </div>
      <div>
        <Paper>
          <Box px={7} py={4}>
            <h1>Register</h1>
            <div style={{ margin: "2rem 0" }}>
              <Typography variant="p2" align="left" margin="dense">
                Fill out the registration form carefully.
              </Typography>
            </div>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    id="firstname"
                    name="firstname"
                    label="Full Name"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("firstname")}
                    error={errors.firstname ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.firstname?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="Middle Name"
                    name="Middle Name"
                    label="Middle Name"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("MiddleName")}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    id="LastName"
                    name="LastName"
                    label="Last Name"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("LastName")}
                    error={errors.LastName ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.LastName?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="Address"
                    name="Address"
                    label="Address"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("Address")}
                    error={errors.Address ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.Address?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="City"
                    name="City"
                    label="City"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("City")}
                    error={errors.City ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.City?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="Postal"
                    name="Postal"
                    label="Postal / Zip code"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("Postal")}
                    error={errors.Postal ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.Postal?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="PhoneNumber"
                    name="PhoneNumber"
                    label="Phone Number"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("PhoneNumber")}
                    error={errors.PhoneNumber ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.PhoneNumber?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Email"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("email")}
                    error={errors.email ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.email?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="Nationality"
                    name="Nationality"
                    label="Nationality"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("Nationality")}
                    error={errors.Nationality ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.Nationality?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="Date"
                    name="Date"
                    label="Date Of Birth"
                    type="date"
                    defaultValue="Select DOB"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("Date")}
                    error={errors.Date ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {/* <TextField
                    required
                    id="Date"
                    name="Date"
                    label="Date Of Birth"
                  /> */}
                  <Typography variant="inherit" color="textSecondary">
                    {errors.Date?.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="Gender"
                    name="Gender"
                    label="Gender"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...register("Gender")}
                    error={errors.Gender ? true : false}
                  />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.Gender?.message}
                  </Typography>
                </Grid>

                <div className={classes.root}>
                  <Typography variant="inherit" color="textSecondary">
                    Upload Signature *
                  </Typography>
                  <Card className={classes.cardContainer}>
                    <CardContent
                      className={
                        uploadState !== "uploaded"
                          ? classes.cardRoot
                          : classes.cardRootHide
                      }
                    >
                      <Grid container justify="center" alignItems="center">
                        <input
                          accept="image/jpeg,image/png,image/tiff,image/webp"
                          className={classes.input}
                          id="contained-button-file"
                          name="logo"
                          //   ref={register({ required: true })}
                          type="file"
                          onChange={handleUploadClick}
                        />
                        <label
                          htmlFor="contained-button-file"
                          className={
                            uploadState === "uploaded" ? classes.input : null
                          }
                        >
                          <Fab component="span" className={classes.button}>
                            <AddPhotoAlternateIcon />
                          </Fab>
                        </label>
                      </Grid>
                    </CardContent>

                    {uploadState === "uploaded" && (
                      <CardActionArea onClick={handleResetClick}>
                        <img className={classes.logo} src={image} alt="LOGO" />
                      </CardActionArea>
                    )}
                  </Card>
                  {progressPercent !== 0 && (
                    <progress value={progressPercent} max="100" />
                  )}
                </div>

                {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="acceptTerms"
                      defaultValue="false"
                      inputRef={register()}
                      render={({ field: { onChange } }) => (
                        <Checkbox
                          color="primary"
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={
                    <Typography
                      color={errors.acceptTerms ? "error" : "inherit"}
                    >
                      I have read and agree to the Terms *
                    </Typography>
                  }
                />
                <br />
                <Typography variant="inherit" color="textSecondary">
                  {errors.acceptTerms
                    ? "(" + errors.acceptTerms.message + ")"
                    : ""}
                </Typography>
              </Grid> */}
              </Grid>

              <Box mt={3} style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    image ? handleSubmit(onSubmit) : notifySign();
                  }}
                >
                  Register
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
      </div>
    </div>
  );
}

export default Register;
