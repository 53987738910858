import React from "react";
import Navbar from "../../Components/Navbar";
import Our_Service from "../../Assets/Service/Our_service_Img.png";
import Img_Card from "../../Components/Img_Card";
import Card1 from "../../Assets/Home/BannerDesktop.png";
import { Link } from "react-router-dom";
import Event_Card from "../../Components/Event_Card";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

function Event() {
  const [matchData, setMatchData] = React.useState();
  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Events"));
      const dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ id: doc.id, ...doc.data() });
      });
      // Sort the data by date
      dataArray.sort((a, b) => new Date(b.date) - new Date(a.date));
      console.log(dataArray); // Process or display the sorted data
      // Store the sorted data in a useState hook
      setMatchData(dataArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Navbar Event={true} />
      <div className="Blog_Content">
        <div className="Blog_Cards">
          <div className="Cards_event">
            {matchData?.map((e, index) => {
              return (
                <Event_Card
                  Img_Card_Image={e.Img}
                  Img_Card_Heading={e.Title}
                  Img_Card_SubHeading={e.SubTitle}
                  Img_Card_Para={e.Description}
                  Img_Card_Date={e.Date}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Event;
