import React from "react";
import Logo from "../Assets/Home/Logo.png";
import "./Navbar.css";
import "../Containers/Home/Home.css";
import { Link } from "react-router-dom";
import Close_Icon from "../Assets/Home/Close_Icon.png";
import Menu from "../Assets/Home/menu.png";
import { isMobile } from "react-device-detect";

function Navbar(props) {
  const [expand, Setexpand] = React.useState(isMobile ? false : true);
  const { Matchcentere, Event, members, Home } = props;

  return (
    <div className={expand ? "Navbar" : "Smallnav"}>
      <Link to="/">
        <div className="Nav_Logo">
          <img
            className="Logo"
            src={Logo}
            alt="Logo"
            style={{ height: "3.5rem" }}
          />
        </div>
      </Link>

      <diV className="work_btn_Main">
        <div className={expand ? "none" : "Mbl_Nav_Menue_small"}>
          <Link to="/">
            <diV>
              {" "}
              <img className="MobileLogo" src={Logo} alt="MobileLogo" />{" "}
            </diV>
          </Link>
          <diV
            className="MenuIcon"
            onClick={() => {
              Setexpand(true);
            }}
          >
            <button className="Nav_btn">
              <img className="MenuIcon" src={Menu} alt="Logo" />
            </button>
          </diV>
        </div>
        <div className={expand ? "Mobile_Nav" : "none"}>
          {/* Mobile Part of Nav */}
          <div className="Mbl_Nav_Menue">
            <diV>
              {" "}
              <img className="MobileLogo" src={Logo} alt="MobileLogo" />{" "}
            </diV>
            <diV
              className="MenuIcon"
              onClick={() => {
                Setexpand(false);
              }}
            >
              <button className="Nav_btn">
                <img className="MenuIcon" src={Close_Icon} alt="Logo" />
              </button>
            </diV>
          </div>

          <ul className="Navbar_Buttons">
            <li>
              <Link to="/">
                <button className={Home ? "Nav_btn_active" : "Nav_btn"}>
                  Home
                </button>
                <hr className={Home ? "Underline_HR_Nav" : "none"}></hr>
              </Link>
            </li>
            <li>
              <Link to="/MatchCentere">
                <button className={Matchcentere ? "Nav_btn_active" : "Nav_btn"}>
                  Match Center
                </button>
                <hr className={Matchcentere ? "Underline_HR_Nav" : "none"}></hr>
              </Link>
            </li>
            <li>
              <Link to="/Event">
                <button className={Event ? "Nav_btn_active" : "Nav_btn"}>
                  Events
                </button>
                <hr className={Event ? "Underline_HR_Nav" : "none"}></hr>
              </Link>
            </li>
            <li>
              <Link to="/Members">
                <button className={members ? "Nav_btn_active" : "Nav_btn"}>
                  Members
                </button>
                <hr className={members ? "Underline_HR_Nav" : "none"}></hr>
              </Link>
            </li>
          </ul>
        </div>
      </diV>
      <Link
        to="/Contact_Us"
        style={{ paddingTop: "6px" }}
        className={expand ? "try" : "none"}
      >
        <button className="Work_with_btn">Contact Us</button>
      </Link>
    </div>
  );
}

export default Navbar;
