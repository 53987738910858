import React from "react";
import "../Home/Home.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Our_Story from "../../Assets/Home/About us imahe.png";
import current from "../../Assets/Home/Slide.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Navbar from "../../Components/Navbar";
import History from "../../Assets/Home/History.png";
import image7 from "../../Assets/Home/Image 7.png";
import Zoom from "react-reveal/Zoom";
import { Carousel } from "react-responsive-carousel";
import carousel2 from "../../Assets/Home/current2.png";
import carousel3 from "../../Assets/Home/current3.png";
import Slide from "react-reveal/Slide";
function Netherlands() {
  const [history, sethistory] = React.useState(false);
  const [currentState, setcurrentState] = React.useState(false);
  const [Future, setFuture] = React.useState(false);

  return (
    <div>
      <Navbar />
      <div
        className="Our_Story"
        style={{
          background: "#EAF3FE",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <div className="Our_Story_Group">
          <div>
            <img className="Bat" src={Our_Story} alt="Our_Story_Img" />
          </div>

          <Slide right cascade>
            <div className="Our_Story_Txt">
              <h1>Introduction</h1>
              <hr className="Underline_HR"></hr>
              <p2>
                Cricket is not the most popular sport in the Netherlands, but it
                has a small but dedicated following. The Netherlands has a
                national cricket team that competes in international
                tournaments, and there are also several domestic cricket leagues
                throughout the country. In this script, we will explore the
                history and current state of cricket in the Netherlands.
              </p2>
            </div>
          </Slide>
        </div>
      </div>

      <div
        className="Our_Service_Group"
        style={{ marginTop: 0, background: "#fff" }}
      >
        <Slide left cascade>
          <div className="Our_Service_Txt">
            <h1>History Of Cricket In The Netherlands</h1>
            <hr className="Underline_HR"></hr>
            <p2>
              Cricket has been played in the Netherlands since the early 19th
              century, with the first recorded match taking place in 1816. In
              the early years, the sport was mainly played by the British expat
              community in the country. However , by th 20th century, cricket
              had gained popularity among the Dutch themselves.
            </p2>
            {console.log(history)}
            {history ? (
              <p2>
                Cricket has been played in the Netherlands since the early 19th
                century, with the first recorded match taking place in 1816. In
                the early years, the sport was mainly played by the British
                expat community in the country. However , by th 20th century,
                cricket had gained popularity among the Dutch themselves, and
                the first Dutch cricket club was founded in 1883.
              </p2>
            ) : (
              <button
                className="Know_More_btn"
                onClick={() => {
                  sethistory(true);
                }}
              >
                Read More <ArrowRightAltIcon className="arrow" />
              </button>
            )}
          </div>
        </Slide>
        <div>
          <img className="Bat" src={History} alt="Our_Service_Img" />
        </div>
      </div>

      <div
        className="Our_Story"
        style={{ background: "#EAF3FE", paddingBottom: "3rem" }}
      >
        <div className="Our_Story_Group">
          <div>
            <Carousel
              swipeable={true}
              emulateTouch={true}
              autoPlay={true}
              centerMode={false}
              showArrows={true}
              showStatus={false}
              showThumbs={false}
              className="Bat"
            >
              <img
                className="Our_Story_Img"
                src={current}
                alt="Our_Story_Img"
              />
              <img
                className="Our_Story_Img"
                src={carousel2}
                alt="Our_Story_Img"
              />
              <img
                className="Our_Story_Img"
                src={carousel3}
                alt="Our_Story_Img"
              />
            </Carousel>
          </div>

          <Slide right cascade>
            <div className="Our_Story_Txt">
              <h1>Current State Of Cricket In The Netherlands</h1>
              <hr className="Underline_HR"></hr>
              <p2>
                Today, there are over 6000 registered cricket players in the
                Netherlands, and the sport is played in all parts of the
                country. The Netherlands has a national cricket team
                thatcompetes in both One day International ans T20 International
                matches. The team has had some notable successes. Welcome to the
                official website of CC De Kikkers Club. One of the oldest and
                most prestigious cricket clubs in the Netherlands, founded in
                1974. Located in Weteringbrug, a beautiful village in the Dutch
                province of North Holland, the club has a long and rich history
                of promoting and playing the game of cricket.
              </p2>
              {currentState ? (
                <p2>
                  In addition to the national team, there are several domestic
                  cricket leagues in the Netherlands, including the Topklasse,
                  which is the highest level of cricket in the country. The
                  Topklasse features eight teams, and the league runs from April
                  to September each year. There are also several lower-level
                  leagues, as well as youth and women's leagues.
                </p2>
              ) : (
                <button
                  className="Know_More_btn"
                  onClick={() => {
                    setcurrentState(true);
                  }}
                >
                  Read More <ArrowRightAltIcon className="arrow" />
                </button>
              )}
            </div>
          </Slide>
        </div>
      </div>
      <div>
        <div className="Our_Service_Group" style={{ background: "#fff" }}>
          <Slide left cascade>
            <div style={{ paddingBottom: "3rem" }} className="Our_Service_Txt">
              <h1>Future Of Cricket In The Netherlands </h1>
              <hr className="Underline_HR"></hr>
              <p2>
                The Future of Cricket in Netherlands looks bright, with the
                sport continuing to grow in popularity. In recent years, there
                has been an increased focus on developing the youth and women's
                cricket program in the country, which should help to ensure the
                continued growth and success of the sport.
              </p2>
              {Future ? (
                <p2>
                  In addition, the Netherlands Cricket Board has been working to
                  improve the infrastructure for cricket in the country, with
                  new facilities and pitches being built across the country.
                  This should help to attract more players to the sport and
                  provide better training and playing opportunities for existing
                  players. In conclusion, cricket may not be the most popular
                  sport in the Netherlands, but it has a dedicated following and
                  a rich history in the country. With a national team that has
                  achieved some notable successes in international tournaments,
                  and a growing domestic cricket scene, the future looks bright
                  for cricket in the Netherlands.
                </p2>
              ) : (
                <button
                  className="Know_More_btn"
                  onClick={() => {
                    setFuture(true);
                  }}
                >
                  Read More <ArrowRightAltIcon className="arrow" />
                </button>
              )}
            </div>
          </Slide>
          <Zoom cascade>
            <div>
              <img className="Bat" src={image7} alt="Our_Story_Img" />
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
}

export default Netherlands;
