import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Our_Story from "../../Assets/Home/Our_Story.png";
import Our_Service from "../../Assets/Home/Our_Service_Image.png";
import Our_Service2 from "../../Assets/Home/Our_Service_Image2.png";
import Our_Service3 from "../../Assets/Home/our3.png";
import Index from "../Img_Card_Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Navbar from "../../Components/Navbar";
import carousel2 from "../../Assets/Home/Card2.png";
import carousel3 from "../../Assets/Home/slide3.png";
import image7 from "../../Assets/Home/Image 7.png";
import axual from "../../Assets/Home/axul.png";
import YoutubeEmbed from "../../Components/YoutubeEmbed";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import { HashLink } from "react-router-hash-link";
function Home() {
  return (
    <div className="Home">
      <Navbar Home={true} />
      <div className="Banner">
        <div className="Banner_Heading">
          <h1 className="Banner_H1">
            "Bat, bowl, catch - cricket's a perfect match!"
          </h1>

          <p1>
            Cricket is a popular bat-and-ball game played in over 100 countries.
            Two teams of eleven players each play, with one batting and the
            other fielding. The aim is to score runs while preventing the
            opposition from doing.
          </p1>
          <Link to="/register" style={{ paddingTop: "6px", zIndex: "10" }}>
            <button className="Work_with_btn">Join now</button>
          </Link>
        </div>
      </div>
      <div className="Banner_Label">
        <Zoom cascade>
          <div>
            <h2 className="Banner_Label_Content">
              "Step up to the crease and immerse yourself in the world of
              cricket - a sport that's full of action, strategy, and
              unforgettable moments!"
            </h2>
          </div>
        </Zoom>
      </div>
      <div className="Our_Story">
        <div className="Our_Story_Group">
          <div>
            <Carousel
              swipeable={true}
              emulateTouch={true}
              autoPlay={true}
              centerMode={false}
              showArrows={true}
              showStatus={false}
              showThumbs={false}
            >
              <img
                className="Our_Story_Img"
                src={Our_Story}
                alt="Our_Story_Img"
              />
              <img
                className="Our_Story_Img"
                src={carousel2}
                alt="Our_Story_Img"
              />
              <img
                className="Our_Story_Img"
                src={carousel3}
                alt="Our_Story_Img"
              />
            </Carousel>
          </div>

          <Slide right cascade>
            <div className="Our_Story_Txt">
              <h1>About Us</h1>
              <hr className="Underline_HR"></hr>
              <p2>
                Welcome to the official website of CC De Kikkers Club. One of
                the oldest and most prestigious cricket clubs in the
                Netherlands, founded in 1974. Located in Weteringbrug, a
                beautiful village in the Dutch province of North Holland, the
                club has a long and rich history of promoting and playing the
                game of cricket.
              </p2>
              <Link to="/About_Us">
                <button className="Know_More_btn">
                  Know more <ArrowRightAltIcon className="arrow" />
                </button>
              </Link>
            </div>
          </Slide>
        </div>
      </div>

      <div className="Our_Service_Group">
        <Slide left cascade>
          <div className="Our_Service_Txt">
            <h1>Our Facilities</h1>
            <hr className="Underline_HR"></hr>
            <p2>
              Cricket is a popular bat-and-ball game played in over 100
              countries. Two teams of eleven players each play, with one batting
              and the other fielding. The aim is to score runs while preventing
              the opposition from doing so. Cricket has different formats, and
              its history is rich with legendary players and memorable moments.
            </p2>

            <HashLink to="/About_Us#facility">
              <button className="Know_More_btn">
                Know more <ArrowRightAltIcon className="arrow" />
              </button>
            </HashLink>
          </div>
        </Slide>
        <div>
          <Carousel
            swipeable={true}
            emulateTouch={true}
            autoPlay={true}
            centerMode={false}
            showArrows={true}
            showStatus={false}
            showThumbs={false}
          >
            <img
              className="Our_Service_Img"
              src={Our_Service}
              alt="Our_Service_Img"
            />
            <img
              className="Our_Service_Img"
              src={Our_Service2}
              alt="Our_Service_Img"
            />
            <img
              className="Our_Service_Img"
              src={Our_Service3}
              alt="Our_Service_Img"
            />
          </Carousel>
        </div>
      </div>
      <div className="to_invest">
        <div className="Find_Place_Heading">
          <div className="Find_Place_H1">
            <h1>Gallery</h1>
            <hr className="Underline_HR"></hr>
          </div>
        </div>
        <div className="Find_Place_Image_Cards">
          <Index />
        </div>
      </div>
      <div className="Our_Service_Group" style={{ padding: "7rem " }}>
        <Slide left cascade>
          <div className="Our_Service_Txt">
            <h1>Cricket In The Netherlands </h1>
            <hr className="Underline_HR"></hr>
            <p2>
              Cricket is not the most popular sport in the Netherlands, but it
              has a small but dedicated following. The Netherlands has a
              national cricket team that competes in international tournaments
              ...
            </p2>
            <Link to="/cricket_netherland">
              <button className="Know_More_btn">
                Know more <ArrowRightAltIcon className="arrow" />
              </button>
            </Link>
          </div>
        </Slide>
        <Zoom cascade>
          <div>
            <img className="Bat" src={image7} alt="Our_Story_Img" />
          </div>
        </Zoom>
      </div>

      <div className="Client_Say">
        <h1 className="Home_Testimonial_Heading">Partners & Sponsors</h1>
      </div>

      <div className="Our_Story_Group">
        <div className="video">
          <YoutubeEmbed embedId="yjZLvYgzqhI" />
        </div>
        <Slide right cascade>
          <div className="Our_Story_Txt">
            <img className="axual" src={axual} alt="Our_Story_Img" />

            <p2>
              Axual is a Dutch software organization founded in 2015 by Jeroen
              van Disseldorp and is located in Utrecht, The Netherlands. Axual
              offers a complete event streaming platform that's complementary to
              Apache Kafka. The platform offers unique self-service capabilities
              with which teams can easily set up and manage data streams through
              an intuitive user interface.
            </p2>

            <a href="https://axual.com/" target="_blank">
              <button className="Know_More_btn">
                Know more <ArrowRightAltIcon className="arrow" />
              </button>
            </a>
          </div>
        </Slide>
      </div>
    </div>
  );
}

export default Home;
