import React from "react";
import "./Img_Card.css";

function Img_Card(props) {
  const {
    Img_Card_Image,
    Img_Card_Heading,
    Img_Card_SubHeading,
    Img_Card_Para,
    Img_Card_Date,
  } = props;

  return (
    <div className="Img_Card">
      <img className="Img_Card_Img" src={Img_Card_Image} alt="Img_Card_Img" />

      {Img_Card_Para && (
        <div className="Img_Card_Content">
          <h2 style={{ fontWeight: "bold" }} className="para">
            {Img_Card_Heading}
          </h2>
          <h3 style={{ color: "white" }}>{Img_Card_SubHeading}</h3>
          <p style={{ color: "white" }}>{Img_Card_Para}</p>
          <p5 style={{ color: "white" }}>{Img_Card_Date}</p5>
        </div>
      )}
    </div>
  );
}

export default Img_Card;
