import React from "react";
import Navbar from "../../Components/Navbar";
import Card2 from "../../Assets/Home/contact.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import "../../Components/Footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

function Contact() {
  return (
    <div className="Contact">
      <Navbar />
      <div
        className="Our_Office"
        style={{ paddingBottom: "4rem", justifyContent: "space-around" }}
      >
        <div
          className="Our_Story_Group"
          style={{ justifyContent: "space-around" }}
        >
          <div className="Our_Story_Txt">
            <h1>Contact Us</h1>
            <hr className="Underline_HR"></hr>
            <h3>Competition Sec.</h3>
            <p>Pratheek Perala</p>
            <h3 className="Contact_no">Email Address :</h3>
            <p>perala.pratheek@gmail.com</p>
            <h3 className="Contact_no">Contact number :</h3>
            <p>+31 (0) 643547666</p>
          </div>
          <div>
            <img className="Our_Story_Img" src={Card2} alt="Our_Story_Img" />
          </div>
        </div>
      </div>
      <div
        className="follow"
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2444.13367755316!2d4.6479244!3d52.2227891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5e7ea7a01259f%3A0xeb0ac6d47d52fda1!2sCC%20de%20Kikkers!5e0!3m2!1sen!2sin!4v1682616123122!5m2!1sen!2sin"
          style={{ height: "35rem", width: "60%", minWidth: "15rem" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <div style={{ background: "#EAF3FE", padding: "5rem" }}>
          <h2>CC De Kikkers</h2>
          <p>Lisserweg 1b, 2156 LA Weteringbrug</p>
          <h3 className="Contact_no">Email</h3>
          <p>ccdekikkers@hotmail.com</p>
          <h3 className="Contact_no">Follow us</h3>
          <div className="Social_Media_Icons" style={{ marginLeft: "0" }}>
            <a
              href="https://www.instagram.com/ccdekikkers/"
              style={{ color: "black" }}
              target="_blank"
            >
              <InstagramIcon></InstagramIcon>
            </a>
            <a
              href="https://www.facebook.com/ccde.kikkers.9"
              style={{ color: "black" }}
              target="_blank"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.youtube.com/@ccdekikkers/"
              style={{ color: "black" }}
              target="_blank"
            >
              <YouTubeIcon />
            </a>
          </div>
          <hr className="Underline_HR"></hr>
          <a href=" https://goo.gl/maps/HCK47u9q3wEhEEZt7" target="_blank">
            <button className="Know_More_btn">
              Route <ArrowRightAltIcon className="arrow" />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
