import React from "react";
import Navbar from "../../Components/Navbar";
import "../Home/Home.css";

function Privacy() {
  return (
    <div className="">
      <Navbar />
      <h1 style={{ textAlign: "center", padding: "2rem" }}>Privacy Policy</h1>
      <div
        style={{ padding: "2rem", display: "flex", flexDirection: "column" }}
      >
        <p2>06-05-2023</p2>
        <p2 className="Card_Heading">Introduction</p2>
        <p2>
          This privacy policy outlines how we collect, use, and protect your
          personal information when you use our website
          ("https://cckikkers.nl"). We take your privacy seriously and are
          committed to safeguarding your personal information in compliance with
          the General Data Protection Regulation (GDPR) and the Dutch Data
          Protection Act (DDPA). By using the Website and providing your
          personal information, you agree to the terms of this privacy policy.
        </p2>
        <p2>
          Information We Collect We collect personal information that you
          provide to us when you register for a membership account on the
          website, such as your name, email address, and phone number. We may
          also collect other information you provide to us when you contact us
          for customer support or participate in surveys or contests.
        </p2>
        <p2 className="Card_Heading">How We Use Your Information</p2>
        <p2>
          We use your personal information to: Communicate with you about your
          membership account and other Website-related matters. Improve the
          Website and its functionality. Provide support and respond to your
          inquiries. Conduct surveys, contests, and other promotions. Comply
          with legal and regulatory requirements. We may also use your
          information for other purposes with your consent.
        </p2>
        <p2 className="Card_Heading">Sharing Your Information</p2>
        <p2>
          We do not sell, trade, or rent your personal information to third
          parties. We may share your information with trusted third-party
          service providers who assist us in operating the Website and providing
          you with our services. These service providers are contractually
          obligated to keep your information confidential and use it only for
          the purposes for which we provide it to them. We may also share your
          information in response to legal requests or to protect our rights and
          property.
        </p2>
        <p2 className="Card_Heading">Data Retention</p2>
        <p2>
          We retain your personal information only for as long as necessary to
          fulfill the purposes for which it was collected, unless a longer
          retention period is required by law. We will delete your personal
          information upon your request, except for any information that we are
          required to retain for legal or regulatory purposes. Your Rights You
          have the right to access, correct, and delete your personal
          information at any time. You also have the right to object to the
          processing of your information or to request that we restrict its
          processing. To exercise these rights, please contact us using the
          contact information below.
        </p2>
        <p2 className="Card_Heading">Security</p2>
        <p2>
          We take reasonable measures to protect your personal information from
          unauthorized access, disclosure, or misuse. However, no data
          transmission over the internet is 100% secure, and we cannot guarantee
          the security of your information.
        </p2>
        <p2>Changes to this Privacy Policy</p2>
        <p2>
          We may update this privacy policy from time to time by posting a new
          version on the Website. We encourage you to review this policy
          periodically for any changes.
        </p2>
        <p2 className="Card_Heading">Contact Us</p2>
        <p2>
          If you have any questions or concerns about this privacy policy or how
          we handle your personal information, please contact us at
          ccdekikkers@hotmail.com
        </p2>
      </div>
    </div>
  );
}

export default Privacy;
