import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import Logo from "../Assets/Home/logo (4).png";
import axual from "../Assets/Home/Group 2.png";
function Footer() {
  return (
    <div className="Footer">
      <diV className="Footer_Heading"></diV>
      <diV className="Nav_Footer_Lgo">
        <Link to="/">
          <img className="Nav_Footer_Logo" src={Logo} alt="Logo" />
        </Link>

        <diV>
          <diV>
            <div>
              <ul className="Footer_Buttons">
                <li>
                  <Link to="/MatchCentere">
                    <button className="Footer_Nav">Match Center</button>
                  </Link>
                </li>
                <li>
                  <Link to="/Event">
                    <button className="Footer_Nav">Events</button>
                  </Link>
                </li>
                <li>
                  <Link to="/Contact_Us">
                    <button className="Footer_Nav">Contact Us</button>
                  </Link>
                </li>
                <li>
                  <a
                    href=" https://goo.gl/maps/HCK47u9q3wEhEEZt7"
                    target="_blank"
                  >
                    <button className="Footer_Nav">Location</button>
                  </a>
                </li>
                <li>
                  <Link to="/Privacy">
                    <button className="Footer_Nav">Privacy Policy</button>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="Footer_Mobile">
              <ul className="Footer_Buttons_Mobile">
                <div className="Footer_Nav_Right_Part">
                  <li>
                    <Link to="/MatchCentere">
                      <button className="Footer_Nav">Match Center</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <button className="Footer_Nav">Events</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Contact_Us">
                      <button className="Footer_Nav">Contact Us</button>
                    </Link>
                  </li>
                </div>
                <div className="Footer_Nav_Right_Part">
                  <li>
                    <a
                      href=" https://goo.gl/maps/HCK47u9q3wEhEEZt7"
                      target="_blank"
                    >
                      <button className="Footer_Nav">Location</button>
                    </a>
                  </li>

                  <li>
                    <Link to="/Privacy">
                      <button className="Footer_Nav">Privacy Policy</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/FAQs">
                      <button className="Footer_Nav">Members</button>
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
          </diV>
          <div className="Social_Media_Icons">
            <a
              href="https://www.instagram.com/ccdekikkers/"
              style={{ color: "white" }}
              target="blank"
            >
              <InstagramIcon></InstagramIcon>
            </a>
            <a
              href="https://www.facebook.com/ccde.kikkers.9"
              style={{ color: "white" }}
              target="_blank"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.youtube.com/@ccdekikkers/"
              style={{ color: "white" }}
              target="_blank"
            >
              <YouTubeIcon />
            </a>
          </div>
          <div className="Estate">
            <p>Copyright © 2023 CC De Kikkers.</p>
          </div>
        </diV>
        <div>
          <a href="https://axual.com/" target="_blank">
            <div
              className="para"
              style={{
                marginBottom: "1rem",
              }}
            >
              <p>Partners & Sponsors</p>
            </div>
            <img
              style={{
                marginLeft: "3.6rem",
              }}
              className="axual"
              src={axual}
              alt="Our_Story_Img"
            />
          </a>
        </div>
      </diV>
    </div>
  );
}

export default Footer;
